import React from "react";

const PageCardSkeleton = () => {
    return (
        <div className="animate-pulse flex flex-col overflow-hidden rounded-lg shadow-md bg-white border border-gray-200">
            <div className="relative flex-shrink-0">
                <div className="w-full h-48 bg-gray-300"></div>
                <div className="absolute top-4 left-4 w-20 h-6 bg-gray-400 rounded-full"></div>
            </div>
            <div className="flex flex-col flex-grow p-5 space-y-4">
                <div className="h-6 bg-gray-300 rounded w-3/4"></div>
                <div className="space-y-2">
                    <div className="h-4 bg-gray-300 rounded"></div>
                    <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                </div>
                <div className="mt-auto pt-4 border-t border-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="w-24 h-4 bg-gray-300 rounded"></div>
                        <div className="flex space-x-2">
                            <div className="w-20 h-4 bg-gray-300 rounded"></div>
                            <div className="w-16 h-4 bg-gray-300 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageCardSkeleton;