import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaSearch, FaEnvelope } from 'react-icons/fa';
import notfoundImage from "../assets/404-illustration.svg";

const NotFound = ({heading}) => {
  return (
    <div className="min-h-[90vh] bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-[650px] w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {heading || 'Oops! Page Not Found'}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            The page you're looking for doesn't exist or has been moved.
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <img
            src={notfoundImage}
            alt="404 Illustration"
            className="mx-auto h-72 w-auto"
          />
          <div className="grid grid-cols-3 gap-4">
            <Link
              to="/"
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-logoColor hover:bg-darklogoColor transition duration-150 ease-in-out"
            >
              <FaHome className="mr-2" /> Home
            </Link>
            <Link
              to="/pages/search"
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-logoColor bg-white border-logoColor hover:bg-gray-50 transition duration-150 ease-in-out"
            >
              <FaSearch className="mr-2" /> Search
            </Link>
            <Link
              to="/contact"
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-logoColor bg-white border-logoColor hover:bg-gray-50 transition duration-150 ease-in-out"
            >
              <FaEnvelope className="mr-2" /> Contact
            </Link>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm text-gray-500">
            If you believe this is an error, please{' '}
            <Link to="/contact" className="font-medium text-logoColor hover:text-darklogoColor">
              contact us
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;