import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { sendContactForm } from '../services/operations/categoryAPI';

const ContactUs = () => {
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);

  const onSubmit = (data) => {
    dispatch(sendContactForm(data,reset,setLoading));
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] bg-gray-100 px-4 py-10">
      <div className="bg-white shadow-lg rounded-lg p-8 md:p-12 lg:p-16 w-full max-w-2xl">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Contact Us</h2>
        <p className="text-gray-600 mb-6">
          Have questions or need assistance? Fill out the form below, and we'll get back to you as soon as possible.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
              Your Name
            </label>
            <input
              {...register('name', { required: 'Name is required' })}
              type="text"
              id="name"
              className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-logoColor ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter your name"
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
              Your Email
            </label>
            <input
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: 'Invalid email address'
                }
              })}
              type="email"
              id="email"
              className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-logoColor ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter your email"
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
              Your Message
            </label>
            <textarea
              {...register('message', { required: 'Message is required', maxLength:2000 })}
              id="message"
              className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-logoColor ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter your message"
              rows="4"
            ></textarea>
            <p className='text-xs text-gray-500'>max-length: 2000</p>
            {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message.message}</p>}
          </div>
          <button
            type="submit"
            className={`w-full bg-logoColor text-white font-medium py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-200 ${loading ? "opacity-50":"opacity-100"}`}
            disabled={loading}
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
