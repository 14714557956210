
import { createSlice } from "@reduxjs/toolkit"

const initialState ={
    loading:false,
    allCategories:[],
    
}

const categorySlice= createSlice({
    name: "category",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAllCategories: (state, action) => {
            state.allCategories = action.payload
        },
      
    },
})

export const {setLoading, setAllCategories}= categorySlice.actions;

export default categorySlice.reducer;