const BASE_URL = process.env.NODE_ENV === 'production' ? "https://backend.tipconcepts.in": "http://localhost:5000";

export const pageEndPoints={
    GET_PAGE_API: `${BASE_URL}/api/v1/page`,
    FEATURED_PAGES_API: `${BASE_URL}/api/v1/pages/featured`,
    SEARCH_PAGES_API: `${BASE_URL}/api/v1/pages/search`,
    HOME_PAGE_DATA_API: `${BASE_URL}/api/v1/pages/home`,
}

export const categoryEndPoints={
    GET_ALL_CATEGORIES_API: `${BASE_URL}/api/v1/categories`,
}

export const contactEndPoints={
    SEND_CONTACT_API: `${BASE_URL}/api/v1/contact`,
}

