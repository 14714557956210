import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeLayout from "./layout/HomeLayout";
import Home from "./pages/Home";
import Page from "./pages/Page";
import Search from "./pages/Search";
import NotFound from "./pages/NotFound";
import ContactUs from "./pages/ContactUs";
import TermsAndConditions from "./pages/TermsAndConditions";
import ScrollToTop from "./components/common/ScrollToTop";

const App = () => {
    return (
        <div className="w-full h-full min-h-screen bg-white text-black">
        <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomeLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="page/:slug" element={<Page/>}/>
                    <Route path="pages/search" element={<Search/>}/>
                    <Route path="contact" element={<ContactUs/>}/>
                    <Route path="terms" element={<TermsAndConditions/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </Routes>
        </div>
    );
};

export default App;
