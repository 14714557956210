import React from "react";
import { Outlet, Link } from "react-router-dom";
import Navbar from "../components/common/Navbar";
import { FaYoutube, FaInstagram, FaTelegram, FaLinkedin, FaEnvelope, FaBook, FaGraduationCap, FaUsers, FaChalkboardTeacher } from "react-icons/fa";

const HomeLayout = () => {
  const categories = ['RGPV', 'CSVTU'];

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="mt-[64px] flex-grow">
        <Outlet />
      </div>

      {/* Enhanced YouTube Subscribe Section */}
      <div className="bg-gradient-to-r from-logoColor to-darklogoColor text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h2 className="text-4xl font-extrabold tracking-tight">Join Our Learning Community</h2>
              <p className="text-xl">Get access to exclusive educational content, live Q&A sessions, and a supportive network of fellow students.</p>
              <div className="flex flex-wrap gap-2">
                <a
                  href="https://www.youtube.com/@TipConcepts05"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 shadow-md hover:shadow-lg flex items-center justify-center text-lg transform hover:scale-105 text-nowrap "
                >
                  <FaYoutube className="mr-2" size={24} />
                  Subscribe on YouTube
                </a>
                <a
                  href="https://t.me/tipconcepts"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 shadow-md hover:shadow-lg flex items-center text-lg transform hover:scale-105 text-nowrap justify-center"
                >
                  <FaTelegram className="mr-2" size={24} />
                  Join Telegram
                </a>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="bg-white bg-opacity-10 p-6 rounded-lg text-center">
                <FaBook className="mx-auto mb-4" size={40} />
                <h3 className="text-xl font-semibold mb-2">Comprehensive Study Materials</h3>
                <p>Access a wide range of curated resources for your courses.</p>
              </div>
              <div className="bg-white bg-opacity-10 p-6 rounded-lg text-center">
                <FaGraduationCap className="mx-auto mb-4" size={40} />
                <h3 className="text-xl font-semibold mb-2">Exam Preparation</h3>
                <p>Get exam tips, practice tests, and revision strategies.</p>
              </div>
              <div className="bg-white bg-opacity-10 p-6 rounded-lg text-center">
                <FaUsers className="mx-auto mb-4" size={40} />
                <h3 className="text-xl font-semibold mb-2">Student Community</h3>
                <p>Connect with peers, share knowledge, and collaborate.</p>
              </div>
              <div className="bg-white bg-opacity-10 p-6 rounded-lg text-center">
                <FaChalkboardTeacher className="mx-auto mb-4" size={40} />
                <h3 className="text-xl font-semibold mb-2">Expert Guidance</h3>
                <p>Learn from experienced educators and industry professionals.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enhanced Footer */}
      <footer className="bg-gray-100 text-gray-800 pt-16 pb-8">
        <div className="max-w-[1200px] mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
            <div className="flex flex-col items-center md:items-start">
              {/* <img src={logo} alt="TipConcepts Logo" className="w-24 mb-4" /> */}
              <h4 className="text-2xl font-bold mb-4 text-logoColor">About us</h4>
              <p className="text-gray-600 text-center md:text-left">
              Get RGPV & CSVTU Latest B.Tech downloadable notes, important questions, and topic-wise videos for all branches at TipConcepts
              </p>
            </div>
            <div>
              <h4 className="text-2xl font-bold mb-4 text-logoColor">Categories</h4>
              <ul className="space-y-2">
                {categories.map((category, index) => (
                  <li key={index}>
                    <Link
                      to={`/pages/search?category=${category}`}
                      className="text-gray-600 hover:text-logoColor transition duration-300"
                    >
                      {category}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-2xl font-bold mb-4 text-logoColor">Quick Links</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/" className="text-gray-600 hover:text-logoColor transition duration-300">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-gray-600 hover:text-logoColor transition duration-300">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-gray-600 hover:text-logoColor transition duration-300">
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-2xl font-bold mb-4 text-logoColor">Connect With Us</h4>
              <div className="flex space-x-4 mb-6">
                <a
                  href="https://www.instagram.com/tipconcepts?igsh=MTA3OHFoOTY3eG1iZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-logoColor hover:text-opacity-80 transition duration-300 transform hover:scale-110"
                >
                  <FaInstagram size={36} />
                </a>
                <a
                  href="https://www.youtube.com/@TipConcepts05"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-logoColor hover:text-opacity-80 transition duration-300 transform hover:scale-110"
                >
                  <FaYoutube size={36} />
                </a>
                <a
                  href="https://t.me/tipconcepts"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-logoColor hover:text-opacity-80 transition duration-300 transform hover:scale-110"
                >
                  <FaTelegram size={36} />
                </a>
                <a
                  href="https://www.linkedin.com/in/tip-concepts"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-logoColor hover:text-opacity-80 transition duration-300 transform hover:scale-110"
                >
                  <FaLinkedin size={36} />
                </a>
              </div>
              <div className="flex items-center space-x-2">
                <FaEnvelope className="text-logoColor" size={24} />
                <a className="text-gray-600 hover:text-logoColor transition duration-300" href="mailto:tipconcepts05@gmail.com">tipconcepts05@gmail.com</a>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-300 pt-8 text-center">
            <p className="text-gray-600">
              &copy; {new Date().getFullYear()} TipConcepts. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomeLayout;