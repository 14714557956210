import React from 'react';
import { FaScroll, FaUserShield, FaBalanceScale, FaLink, FaShieldAlt, FaEdit, FaEnvelope } from 'react-icons/fa';

const TermsAndConditions = () => {
  const sections = [
    { icon: FaScroll, title: "Use of Content", id: "use-of-content" },
    { icon: FaUserShield, title: "Accuracy of Information", id: "accuracy-of-information" },
    { icon: FaEdit, title: "User-Generated Content", id: "user-generated-content" },
    { icon: FaShieldAlt, title: "Confidentiality and Privacy", id: "confidentiality-and-privacy" },
    { icon: FaLink, title: "Links to External Websites", id: "links-to-external-websites" },
    { icon: FaBalanceScale, title: "Limitation of Liability", id: "limitation-of-liability" },
    { icon: FaEdit, title: "Changes to Terms and Conditions", id: "changes-to-terms" },
    { icon: FaEnvelope, title: "Contact Information", id: "contact-information" },
  ];

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-logoColor mb-8 text-center">Terms and Conditions</h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">Last Updated: 3/9/2024</p>
          <p className="text-lg mb-8">
            Welcome to Tip Concepts. These terms and conditions outline the rules and regulations for the use of our website, located at tipconcepts.in.
          </p>
          <p className="text-lg mb-8">
            By accessing this website, we assume you accept these terms and conditions. Do not continue to use Tip Concepts if you do not agree to all the terms and conditions stated on this page.
          </p>

          <nav className="mb-12">
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {sections.map((section) => (
                <li key={section.id}>
                  <a
                    href={`#${section.id}`}
                    className="flex items-center p-3 bg-gray-100 rounded-lg hover:bg-logoColor hover:text-white transition duration-300"
                  >
                    <section.icon className="mr-3" />
                    <span>{section.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>

          <section id="use-of-content" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">1. Use of Content</h2>
            <p className="mb-2">1.1. The content provided on this website, including notes, previous year question papers, and important questions, is for informational and educational purposes only.</p>
            <p className="mb-2">1.2. The material is prepared based on the official syllabi and question papers from Rajiv Gandhi Proudyogiki Vishwavidyalaya (RGPV) and Chhattisgarh Swami Vivekanand Technical University (CSVTU). However, we do not guarantee that our content will fully cover the required topics or that it will ensure success in exams.</p>
            <p>1.3. You are permitted to download, print, or use the material for personal, non-commercial use. Any other use, including copying, redistribution, or selling, is strictly prohibited.</p>
          </section>

          <section id="accuracy-of-information" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">2. Accuracy of Information</h2>
            <p className="mb-2">2.1. While we strive to ensure that the content on our website is accurate and up to date, we do not make any warranties or representations about the completeness, reliability, or accuracy of the information.</p>
            <p>2.2. Any action you take upon the information you find on this website is strictly at your own risk. Tip Concepts will not be liable for any losses or damages in connection with the use of our website.</p>
          </section>

          <section id="user-generated-content" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">3. User-Generated Content</h2>
            <p className="mb-2">3.1. Our website may allow you to submit queries, suggestions, and topic recommendations through the "Contact Us" page.</p>
            <p className="mb-2">3.2. By submitting this information, you grant us the right to use, modify, and publish your submissions in a non-confidential manner, except where expressly indicated otherwise.</p>
            <p>3.3. We reserve the right to remove any user-generated content that we deem inappropriate or not in line with our policies.</p>
          </section>

          <section id="confidentiality-and-privacy" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">4. Confidentiality and Privacy</h2>
            <p className="mb-2">4.1. We respect your privacy and are committed to protecting any personal information you provide through our website.</p>
            <p className="mb-2">4.2. Any queries, suggestions, or personal information submitted via the "Contact Us" page will be kept confidential and used solely for the purpose of improving our services.</p>
            <p>4.3. We will not share your personal information with third parties without your consent, except where required by law.</p>
          </section>

          <section id="links-to-external-websites" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">5. Links to External Websites</h2>
            <p className="mb-2">5.1. Our website may contain links to external websites that are not operated by us. We have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.</p>
            <p>5.2. We recommend that you review the terms and conditions and privacy policies of any third-party sites you visit through our website.</p>
          </section>

          <section id="limitation-of-liability" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">6. Limitation of Liability</h2>
            <p className="mb-2">6.1. Tip Concepts will not be held responsible for any errors, omissions, or inaccuracies in the content provided on this website.</p>
            <p>6.2. We are not liable for any damages or losses that may result from the use or misuse of the information available on our site.</p>
          </section>

          <section id="changes-to-terms" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">7. Changes to Terms and Conditions</h2>
            <p className="mb-2">7.1. We reserve the right to update these terms and conditions at any time. Any changes will be posted on this page with an updated "Last Updated" date.</p>
            <p>7.2. It is your responsibility to review these terms and conditions periodically to ensure you are aware of any changes.</p>
          </section>

          <section id="contact-information" className="mb-12">
            <h2 className="text-2xl font-semibold text-logoColor mb-4">8. Contact Information</h2>
            <p>8.1. If you have any questions or concerns about these terms and conditions, or if you wish to provide feedback or suggestions, please contact us via the "Contact Us" page on our website.</p>
          </section>

          <div className="mt-12 p-6 bg-gray-100 rounded-lg">
            <p className="text-center text-lg font-semibold">
              By continuing to use our website, you agree to be bound by these terms and conditions. Thank you for using Tip Concepts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;