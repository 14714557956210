import axios from "axios";
import { categoryEndPoints, contactEndPoints } from "../apis";
import { setAllCategories, setLoading } from "../../slices/categorySlice";
import toast from "react-hot-toast";

const { GET_ALL_CATEGORIES_API } = categoryEndPoints;
const {SEND_CONTACT_API} = contactEndPoints;

export const getAllCategories = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(GET_ALL_CATEGORIES_API);
        dispatch(setAllCategories(response.data.data));
        dispatch(setLoading(false));
    } catch (error) {
        console.error("Error fetching pages:", error);
        dispatch(setLoading(false));
    }
};

export const sendContactForm = (formData,reset,setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await axios.post(SEND_CONTACT_API, formData);
        if(!response.data.success){
            throw new Error(response.data.message);
        }
        toast.success(response.data.message);
        reset();
    } catch (error) {
        console.error("Error sending contact form:", error);
    } finally{
        setLoading(false)
    }
}
