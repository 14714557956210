import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getPageByIdOrSlug } from "../services/operations/pageAPI";
import parser from "html-react-parser";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NotFound from "./NotFound";

const Skeleton = () => (
    <div className="animate-pulse space-y-4 max-w-[1200px] mx-auto">
        <div className="bg-gray-300 h-64 w-full"></div>
        <div className="bg-gray-300 h-10 w-3/4 mx-auto mt-4"></div>
        <div className="bg-gray-300 h-6 w-2/4 mx-auto"></div>
        <div className="bg-gray-300 h-64 w-full mt-4"></div>
    </div>
);

const Page = () => {
    const { slug } = useParams();
    const { loading } = useSelector((state) => state.page);
    const [currentPage, setCurrentPage] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [absoluteDivHeight, setAbsoluteDivHeight] = useState(0);
    const dispatch = useDispatch();
    const absoluteDivRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await dispatch(getPageByIdOrSlug(slug));
            if (!data) {
                setNotFound(true);
            } else {
                setCurrentPage(data);
            }
        };
        fetchData();
    }, [dispatch, slug]);

    useEffect(() => {
        if (absoluteDivRef.current) {
            setAbsoluteDivHeight(absoluteDivRef.current.clientHeight);
        }
    }, [currentPage]);

    const totalOffset = absoluteDivHeight / 2 + 20;

    if (loading) {
        return <Skeleton />;
    }

    if (notFound) {
        return <NotFound heading={"No page found with this URL slug"} />;
    }

    const structuredData = currentPage
        ? {
              "@context": "https://schema.org",
              "@type": "Article",
              headline: currentPage.title,
              description: currentPage.subtitle,
              image: currentPage.thumbnail,
              author: {
                  "@type": "Organization",
                  name: "TipConcepts",
              },
              publisher: {
                  "@type": "Organization",
                  name: "TipConcepts",
                  logo: {
                      "@type": "ImageObject",
                      url: "https://www.tipconcepts.in/logo.svg",
                  },
              },
              datePublished: currentPage.createdAt,
              dateModified: currentPage.updatedAt,
          }
        : null;

    return (
        <HelmetProvider>
            {currentPage && (
                <Helmet>
                    <title>{`${currentPage.title} | TipConcepts`}</title>
                    <meta name="description" content={currentPage.subtitle} />
                    <meta name="keywords" content={currentPage.metaTags} />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="article" />
                    <meta
                        property="og:url"
                        content={`https://www.tipconcepts.in/page/${slug}`}
                    />
                    <meta property="og:title" content={currentPage.title} />
                    <meta
                        property="og:description"
                        content={currentPage.subtitle}
                    />
                    <meta property="og:image" content={currentPage.thumbnail} />

                    {/* Twitter Card */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta
                        name="twitter:url"
                        content={`https://www.tipconcepts.in/page/${slug}`}
                    />
                    <meta name="twitter:title" content={currentPage.title} />
                    <meta
                        name="twitter:description"
                        content={currentPage.subtitle}
                    />
                    <meta
                        name="twitter:image"
                        content={currentPage.thumbnail}
                    />

                    {/* Article specific metadata */}
                    <meta
                        property="article:published_time"
                        content={currentPage.createdAt}
                    />
                    <meta
                        property="article:modified_time"
                        content={currentPage.updatedAt}
                    />
                    <meta
                        property="article:section"
                        content={currentPage.category}
                    />

                    {/* Canonical URL */}
                    <link
                        rel="canonical"
                        href={`https://www.tipconcepts.in/page/${slug}`}
                    />

                    {/* Structured Data */}
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                </Helmet>
            )}

            <div className="h-full w-full text-black bg-white">
                {currentPage && (
                    <div className="w-full max-w-[1200px] mx-auto relative px-4 sm:px-6 lg:px-8 pb-8">
                        <div>
                            <img
                                src={currentPage.thumbnail}
                                alt={currentPage.title}
                                loading="lazy"
                                className="w-full h-[200px] md:h-[300px] object-cover shadow-md"
                            />
                            <div
                                ref={absoluteDivRef}
                                className="absolute w-[90%] max-w-[1000px] card text-white py-6 sm:py-8 md:py-10 px-4 sm:px-6 mx-auto transform -translate-y-1/2 left-1/2 -translate-x-1/2 bg-gray-900 bg-opacity-70 rounded-lg"
                            >
                                <h1 className="text-2xl sm:text-3xl font-bold mb-2">
                                    {currentPage.title}
                                </h1>
                                <p className="text-sm sm:text-lg">
                                    {currentPage.subtitle}
                                </p>
                            </div>
                        </div>
                        <div
                            className="flex flex-col items-center"
                            style={{ marginTop: `${totalOffset}px` }}
                        >
                            <div className="w-full flex items-center justify-center">
                                {parser(currentPage.iframe)}
                            </div>
                        </div>
                        <div className="p-4 sm:p-6 flex flex-col gap-4">
                            <div className="prose max-w-none text-justify flex flex-col gap-2">
                                {parser(currentPage.description)}
                            </div>
                            {currentPage.mediaLinks &&
                                currentPage.mediaLinks.media &&
                                currentPage.mediaLinks.media.map((link, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-between items-center gap-2"
                                    >
                                        <Link
                                            to={link}
                                            className="underline text-sm"
                                        >
                                            {link}
                                        </Link>
                                        <a
                                            href={link}
                                            download
                                            className="text-white bg-logoColor px-3 py-2 max-w-max max-h-max rounded-md text-center"
                                        >
                                            Download Media {index + 1}
                                        </a>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </div>
        </HelmetProvider>
    );
};

export default Page;
