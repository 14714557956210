import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { fetchPagesData } from "../services/operations/pageAPI";
import PageCardSkeleton from "../components/skeleton/PageCardSkeleton";
import PageCard from "../components/common/PageCard";
import {
    FaChevronRight,
    FaGraduationCap,
    FaBook,
    FaStar,
} from "react-icons/fa";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
    setAllPages,
    setAllPagesLoading,
    setFeaturedPages,
    setFeaturedPagesLoading,
    setRgpvPages,
    setRgpvPagesLoading,
    setCsvtuPages,
    setCsvtuPagesLoading,
} from "../slices/pageSlice";
import { useNavigate } from "react-router-dom";

const SectionTitle = ({ title, icon: Icon }) => (
    <div className="flex items-center justify-center mb-8">
        <div className="w-1/4 h-0.5 bg-logoColor"></div>
        <h2 className="text-2xl md:text-4xl font-bold text-center mx-4 text-gray-800 flex items-center">
            <Icon className="mr-3 text-logoColor" />
            <span className="text-nowrap">{title}</span>
        </h2>
        <div className="w-1/4 h-0.5 bg-logoColor"></div>
    </div>
);

const LoadMoreButton = ({ category, isFeatured }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        let path = "/pages/search";
        const searchParams = new URLSearchParams();

        if (category) {
            searchParams.append("category", category);
        }

        if (isFeatured) {
            searchParams.append("isFeatured", isFeatured);
        }

        if (searchParams.toString()) {
            path += `?${searchParams.toString()}`;
        }

        navigate(path);
    };

    return (
        <button
            onClick={handleClick}
            className="mt-8 mx-auto block bg-logoColor text-white font-bold py-3 px-8 rounded-full shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex items-center"
        >
            Load More
            <FaChevronRight className="ml-2" />
        </button>
    );
};

const NoDataFound = () => (
    <div className="text-center py-12 bg-gray-100 rounded-lg shadow-inner">
        <p className="text-xl text-gray-600">No data found</p>
    </div>
);

const Home = () => {
    const dispatch = useDispatch();
    const {
        allPages,
        allPagesLoading,
        rgpvPages,
        rgpvPagesLoading,
        csvtuPages,
        csvtuPagesLoading,
        featuredPages,
        featuredPagesLoading,
    } = useSelector((state) => state.page);

    const [hasAttemptedFetch, setHasAttemptedFetch] = useState({
        all: false,
        rgpv: false,
        csvtu: false,
        featured: false,
    });

    const { ref: allPagesRef, inView: allPagesInView } = useInView();
    const { ref: rgpvPagesRef, inView: rgpvPagesInView } = useInView();
    const { ref: csvtuPagesRef, inView: csvtuPagesInView } = useInView();
    const { ref: featuredPagesRef, inView: featuredPagesInView } = useInView();

    useEffect(() => {
        if (allPagesInView && !hasAttemptedFetch.all && !allPagesLoading) {
            dispatch(
                fetchPagesData(
                    { page: 1, limit: 9 },
                    setAllPagesLoading,
                    setAllPages
                )
            );
            setHasAttemptedFetch(prev => ({ ...prev, all: true }));
        }
    }, [allPagesInView, hasAttemptedFetch.all, allPagesLoading, dispatch]);

    useEffect(() => {
        if (rgpvPagesInView && !hasAttemptedFetch.rgpv && !rgpvPagesLoading) {
            dispatch(
                fetchPagesData(
                    { page: 1, limit: 3, category: "RGPV" },
                    setRgpvPagesLoading,
                    setRgpvPages
                )
            );
            setHasAttemptedFetch(prev => ({ ...prev, rgpv: true }));
        }
    }, [rgpvPagesInView, hasAttemptedFetch.rgpv, rgpvPagesLoading, dispatch]);

    useEffect(() => {
        if (csvtuPagesInView && !hasAttemptedFetch.csvtu && !csvtuPagesLoading) {
            dispatch(
                fetchPagesData(
                    { page: 1, limit: 3, category: "CSVTU" },
                    setCsvtuPagesLoading,
                    setCsvtuPages
                )
            );
            setHasAttemptedFetch(prev => ({ ...prev, csvtu: true }));
        }
    }, [csvtuPagesInView, hasAttemptedFetch.csvtu, csvtuPagesLoading, dispatch]);

    useEffect(() => {
        if (featuredPagesInView && !hasAttemptedFetch.featured && !featuredPagesLoading) {
            dispatch(
                fetchPagesData(
                    { page: 1, limit: 3, isFeatured: true },
                    setFeaturedPagesLoading,
                    setFeaturedPages
                )
            );
            setHasAttemptedFetch(prev => ({ ...prev, featured: true }));
        }
    }, [featuredPagesInView, hasAttemptedFetch.featured, featuredPagesLoading, dispatch]);

    const renderPageSection = (
        pages,
        loading,
        category = null,
        isFeatured = null,
        ref
    ) => (
        <div ref={ref} className="mb-20">
            {loading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {[...Array(3)].map((_, index) => (
                        <PageCardSkeleton key={index} />
                    ))}
                </div>
            ) : pages.length > 0 ? (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {pages.map((page) => (
                            <PageCard key={page._id} page={page} />
                        ))}
                    </div>
                    <LoadMoreButton
                        category={category}
                        isFeatured={isFeatured}
                    />
                </>
            ) : (
                <NoDataFound />
            )}
        </div>
    );

    return (
        <HelmetProvider>
            <div className="max-w-[1200px] mx-auto py-8 px-4">
                <Helmet>
                    <title>
                        TipConcepts - Your one-stop resource for RGPV & CSVTU
                    </title>
                    <meta
                        name="description"
                        content="TipConcepts provides university information, study materials, and resources for RGPV and CSVTU students. Explore our latest and featured pages."
                    />
                    <meta
                        name="keywords"
                        content="CSVTU B.Tech Important Topics, RGPV B.Tech Important Topics, RGPV Study Material, CSVTU study Material, RGPV Notes, CSVTU Updates 2024, CSVTU previous year question papers, TipConcepts"
                    />

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.tipconcepts.in/"
                    />
                    <meta
                        property="og:title"
                        content="TipConcepts - Your one-stop resource for RGPV & CSVTU"
                    />
                    <meta
                        property="og:description"
                        content="Explore university information, study materials, and resources for RGPV and CSVTU students at TipConcepts."
                    />
                    <meta
                        property="og:image"
                        content="https://www.tipconcepts.in/logo.svg"
                    />
                </Helmet>
                <div className="bg-gradient-to-r from-logoColor to-darklogoColor text-white py-16 px-8 rounded-3xl shadow-2xl mb-16">
                    <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center">
                        Welcome to TipConcepts
                    </h1>
                    <p className="text-xl text-center max-w-2xl mx-auto">
                        Your one-stop resource for university information, study
                        materials, and more.
                    </p>
                </div>

                <SectionTitle title="Latest Pages" icon={FaBook} />
                <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                    Stay up-to-date with our most recent additions across all
                    categories
                </p>
                {renderPageSection(
                    allPages,
                    allPagesLoading,
                    null,
                    null,
                    allPagesRef
                )}

                <SectionTitle title="RGPV Pages" icon={FaGraduationCap} />
                <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                    Explore the latest updates and resources for RGPV students
                </p>
                {renderPageSection(
                    rgpvPages,
                    rgpvPagesLoading,
                    "RGPV",
                    null,
                    rgpvPagesRef
                )}

                <SectionTitle title="CSVTU Pages" icon={FaGraduationCap} />
                <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                    Discover valuable information for CSVTU students
                </p>
                {renderPageSection(
                    csvtuPages,
                    csvtuPagesLoading,
                    "CSVTU",
                    null,
                    csvtuPagesRef
                )}

                <SectionTitle title="Featured Pages" icon={FaStar} />
                <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                    Highlighted pages curated by our experts
                </p>
                {renderPageSection(
                    featuredPages,
                    featuredPagesLoading,
                    null,
                    true,
                    featuredPagesRef
                )}
            </div>
        </HelmetProvider>
    );
};

export default Home;
