import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allPagesLoading: false,
  allPages: [],
  allPagesPagination: {
    currentPage: 1,
    totalPages: 0,
    limit: 30,
  },

  featuredPagesLoading: false,
  featuredPages: [],
  featuredPagesPagination: {
    currentPage: 1,
    totalPages: 0,
    limit: 3,
  },

  rgpvPagesLoading: false,
  rgpvPages: [],

  csvtuPagesLoading: false,
  csvtuPages: [],
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    // Set loading states
    setAllPagesLoading: (state, action) => {
      state.allPagesLoading = action.payload;
    },
    setFeaturedPagesLoading: (state, action) => {
      state.featuredPagesLoading = action.payload;
    },
    setRgpvPagesLoading: (state, action) => {
      state.rgpvPagesLoading = action.payload;
    },
    setCsvtuPagesLoading: (state, action) => {
      state.csvtuPagesLoading = action.payload;
    },

    // Set pages data
    setAllPages: (state, action) => {
      state.allPages = action.payload;
    },
    setFeaturedPages: (state, action) => {
      state.featuredPages = action.payload;
    },
    setRgpvPages: (state, action) => {
      state.rgpvPages = action.payload;
    },
    setCsvtuPages: (state, action) => {
      state.csvtuPages = action.payload;
    },

    // Set pagination data
    setAllPagesPagination: (state, action) => {
      state.allPagesPagination = action.payload;
    },
    setFeaturedPagesPagination: (state, action) => {
      state.featuredPagesPagination = action.payload;
    },
  },
});

export const {
  setAllPagesLoading,
  setFeaturedPagesLoading,
  setRgpvPagesLoading,
  setCsvtuPagesLoading,
  setAllPages,
  setFeaturedPages,
  setRgpvPages,
  setCsvtuPages,
  setAllPagesPagination,
  setFeaturedPagesPagination,
} = pageSlice.actions;

export default pageSlice.reducer;
