import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSearch, FaTimes, FaFilter } from "react-icons/fa";
import PageCardSkeleton from "../components/skeleton/PageCardSkeleton";
import PageCard from "../components/common/PageCard";
import { getAllCategories } from "../services/operations/categoryAPI";
import { getAllPages } from "../services/operations/pageAPI";
import { useSearchParams } from "react-router-dom";
import noDataFound from "../assets/no-data-found.svg";

const Search = () => {
    const dispatch = useDispatch();
    const { allPages, allPagesLoading, allPagesPagination } = useSelector(
        (state) => state.page
    );
    const { allCategories } = useSelector((state) => state.category);

    const [searchParams] = useSearchParams();

    const initialSearchTerm = searchParams.get("search") || "";
    const initialCategory = searchParams.get("category") || "";
    const initialIsFeatured = searchParams.get("isFeatured") || null;

    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
    const [selectedCategories, setSelectedCategories] = useState(
        initialCategory ? [initialCategory] : []
    );
    const [isFeatured, setIsFeatured] = useState(initialIsFeatured);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllCategories());
    }, [dispatch]);

    useEffect(() => {
        setSearchTerm(searchParams.get("search") || "");
        const category = searchParams.get("category") || "";
        setSelectedCategories(category ? [category] : []);
    }, [searchParams]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            fetchPages();
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchTerm, selectedCategories, isFeatured, currentPage]);

    const fetchPages = () => {
        const params = {
            page: currentPage,
            limit: 15,
            ...(isFeatured !== null && { isFeatured }),
        };
        if (searchTerm) {
            params.search = searchTerm;
        }
        if (selectedCategories.length) {
            params.category = selectedCategories.join(",");
        }
        dispatch(getAllPages(params));
    };

    const handleCategoryToggle = (categoryName) => {
        setSelectedCategories((prev) =>
            prev.includes(categoryName)
                ? prev.filter((name) => name !== categoryName)
                : [...prev, categoryName]
        );
        setCurrentPage(1);
    };

    const handleRemoveFilter = (filter) => {
        if (filter === "featured") {
            setIsFeatured(null);
        } else {
            setSelectedCategories((prev) =>
                prev.filter((name) => name !== filter)
            );
        }
        setCurrentPage(1);
    };
    const renderActiveFilters = () => (
        <div className="flex flex-wrap gap-2 mt-4">
            {selectedCategories.map((categoryName) => (
                <span
                    key={categoryName}
                    className="bg-indigo-600 text-white px-3 py-2 rounded-full text-sm font-medium flex items-center shadow-md"
                >
                    {categoryName}
                    <button
                        onClick={() => handleRemoveFilter(categoryName)}
                        className="ml-2 hover:text-red-300 transition-colors"
                    >
                        <FaTimes />
                    </button>
                </span>
            ))}
            {isFeatured !== null && (
                <span className="bg-indigo-600 text-white px-3 py-2 rounded-full text-sm font-medium flex items-center shadow-md">
                    {isFeatured ? "Featured" : "Not Featured"}
                    <button
                        onClick={() => handleRemoveFilter("featured")}
                        className="ml-2 hover:text-red-300 transition-colors"
                    >
                        <FaTimes />
                    </button>
                </span>
            )}
        </div>
    );

    return (
        <div className="max-w-[1200px] mx-auto pt-4 pb-16 px-4">
            <div className="bg-gradient-to-r from-logoColor to-darklogoColor text-white py-16 px-8 rounded-3xl shadow-2xl mb-16">
                <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center">
                Find What You Need
                </h1>
                <p className="text-xl text-center max-w-2xl mx-auto">
                We've got you covered with a wide range of topics to strengthen your concepts and make engineering easier.
                </p>
            </div>

            <div className="bg-white shadow-2xl rounded-2xl p-6 sm:p-8 mb-12 transition-all duration-300">
                <div className="relative mb-6 sm:mb-8">
                    <input
                        type="text"
                        placeholder="Search pages..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full py-3 sm:py-4 px-4 sm:px-6 pr-12 text-lg sm:text-xl border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent transition-all duration-300"
                    />
                    <FaSearch className="absolute right-4 sm:right-6 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl sm:text-2xl" />
                </div>

                <div className="mb-6 sm:mb-8">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800">
                            Filters
                        </h2>
                        <button
                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                            className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors duration-300"
                        >
                            <FaFilter className="mr-2" />
                            <span className="text-base sm:text-lg font-medium">
                                {isFilterOpen ? "Hide Filters" : "Show Filters"}
                            </span>
                        </button>
                    </div>

                    {isFilterOpen && (
                        <>
                            <div className="mb-6">
                                <h3 className="text-lg sm:text-xl font-semibold mb-3 text-gray-700">
                                    Categories
                                </h3>
                                <div className="flex flex-wrap gap-2 sm:gap-3">
                                    {allCategories.length > 0 &&
                                        allCategories.map((category) => (
                                            <button
                                                key={category._id}
                                                onClick={() =>
                                                    handleCategoryToggle(
                                                        category.name
                                                    )
                                                }
                                                className={`px-3 sm:px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                                                    selectedCategories.includes(
                                                        category.name
                                                    )
                                                        ? "bg-indigo-600 text-white shadow-md"
                                                        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                                                }`}
                                            >
                                                {category.name}
                                            </button>
                                        ))}
                                </div>
                            </div>

                            <div>
                                <h3 className="text-lg sm:text-xl font-semibold mb-3 text-gray-700">
                                    Featured
                                </h3>
                                <div className="flex gap-3 sm:gap-4">
                                    <button
                                        onClick={() => setIsFeatured(true)}
                                        className={`px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-sm font-medium transition-all duration-300 ${
                                            isFeatured === true
                                                ? "bg-indigo-600 text-white shadow-md"
                                                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                                        }`}
                                    >
                                        Featured
                                    </button>
                                    <button
                                        onClick={() => setIsFeatured(false)}
                                        className={`px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-sm font-medium transition-all duration-300 ${
                                            isFeatured === false
                                                ? "bg-indigo-600 text-white shadow-md"
                                                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                                        }`}
                                    >
                                        Not Featured
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {renderActiveFilters()}
            </div>

            <div className="mb-12">
                <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-gray-800">
                    Search Results
                </h2>
                {allPagesLoading ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
                        {[...Array(9)].map((_, index) => (
                            <PageCardSkeleton key={index} />
                        ))}
                    </div>
                ) : allPages.length > 0 ? (
                    <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-8 sm:mb-12">
                            {allPages.map((page) => (
                                <PageCard key={page._id} page={page} />
                            ))}
                        </div>
                        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                            <button
                                onClick={() =>
                                    setCurrentPage((prev) =>
                                        Math.max(prev - 1, 1)
                                    )
                                }
                                disabled={currentPage === 1}
                                className="w-full sm:w-auto px-6 py-3 bg-logoColor text-white text-lg font-medium rounded-lg disabled:opacity-50 transition-all duration-300 hover:bg-darklogoColor"
                            >
                                Previous
                            </button>
                            <span className="text-xl sm:text-2xl font-semibold text-gray-800">
                                Page {currentPage} of{" "}
                                {allPagesPagination.totalPages}
                            </span>
                            <button
                                onClick={() =>
                                    setCurrentPage((prev) =>
                                        Math.min(
                                            prev + 1,
                                            allPagesPagination.totalPages
                                        )
                                    )
                                }
                                disabled={
                                    currentPage ===
                                    allPagesPagination.totalPages
                                }
                                className="w-full sm:w-auto px-6 py-3 bg-logoColor text-white text-lg font-medium rounded-lg disabled:opacity-50 transition-all duration-300 hover:bg-darklogoColor"
                            >
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="bg-white shadow-lg rounded-lg p-8 max-w-xl mx-auto text-center">
                        <img
                            src={noDataFound}
                            alt="No results illustration"
                            className="w-96 mx-auto mb-6"
                        />
                        <h2 className="text-2xl font-semibold mb-4">
                            No Data Found
                        </h2>
                        <p className="text-gray-600 mb-4">
                            We couldn't find any results for your search. Please
                            try again with different keywords.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Search;
