import axios from "axios";
import { pageEndPoints } from "../apis";
import toast from "react-hot-toast";
import { setAllPages, setAllPagesLoading, setAllPagesPagination, setCsvtuPages, setFeaturedPages, setFeaturedPagesLoading, setFeaturedPagesPagination, setRgpvPages } from "../../slices/pageSlice";
import { setAllCategories } from "../../slices/categorySlice";

const {
    FEATURED_PAGES_API,
    GET_PAGE_API,
    HOME_PAGE_DATA_API,
    SEARCH_PAGES_API,
} = pageEndPoints;

export const getHomePageData=()=> async(dispatch)=>{
    try {
        dispatch(setAllPagesLoading(true));
        const response = await axios.get(HOME_PAGE_DATA_API);

        if(!response.data || !response.data.success){
            throw new Error(response.data.message);
        }

        dispatch(setAllPages(response.data.data.allPages))
        dispatch(setFeaturedPages(response.data.data.featuredPages))
        dispatch(setRgpvPages(response.data.data.rgpvPages))
        dispatch(setCsvtuPages(response.data.data.csvtuPages))
        dispatch(setAllCategories(response.data.data.categories))
    } catch (error) {
        console.error(error.message);
        // toast.error("Error getting home page data");
    } finally{
        dispatch(setAllPagesLoading(false));
    }
}



export const getAllPages = (data) => async (dispatch) => {
    try {
        dispatch(setAllPagesLoading(true));
        const response = await axios.get(SEARCH_PAGES_API, { params: data });

        if(!response.data || !response.data.success){
            throw new Error(response.data.message);
        }

        dispatch(setAllPages(response.data.data.pages));
        dispatch(setAllPagesPagination(response.data.data.pagination));
    } catch (error) {
        console.error("Error fetching pages:", error);
        toast.error("Error while fetching data");
    } finally {
        dispatch(setAllPagesLoading(false));
    }
};

export const getPages = (data) => async (dispatch) => {
    try {
        const response = await axios.get(SEARCH_PAGES_API, { params: data });

        if(!response.data || !response.data.success){
            throw new Error(response.data.message);
        }

        return response.data.data;
    } catch (error) {
        console.error("Error fetching pages:", error);
        toast.error("Error while fetching data");
    } 
};

export const getFeaturedPages = (data) => async (dispatch) => {
    try {
        dispatch(setFeaturedPagesLoading(true));
        const response = await axios.get(FEATURED_PAGES_API, { params: data });

        if(!response.data || !response.data.success){
            throw new Error(response.data.message);
        }

        dispatch(setFeaturedPages(response.data.data.pages));
        dispatch(setFeaturedPagesPagination(response.data.data.pagination));
    } catch (error) {
        console.error("Error fetching pages:", error);
        toast.error("Error while fetching data");
    } finally {
        dispatch(setFeaturedPagesLoading(false));
    }
};

export const getPageByIdOrSlug = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${GET_PAGE_API}/${id}`);
        return response.data.data;
    } catch (error) {
        toast.error(error.response.data.message);
    }
};

export const fetchPagesData = (params, setLoading, setData) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
  
      const response = await axios.get(SEARCH_PAGES_API, { params });
  
      if (!response.data || !response.data.success) {
        throw new Error(response.data.message);
      }
  
      dispatch(setData(response.data.data.pages));
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      dispatch(setLoading(false));
    }
  };