import { combineReducers } from "@reduxjs/toolkit";
import pageReducer from "../slices/pageSlice";
import categoryReducer from "../slices/categorySlice"


const rootReducer= combineReducers({
    page: pageReducer,
    category: categoryReducer,
})

export default rootReducer;