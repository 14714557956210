import React from "react";
import { Link } from "react-router-dom";

const PageCard = ({ page }) => {
    return (
        <Link
            to={`/page/${page?.slug}`}
            className="flex flex-col overflow-hidden shadow-md transition-all duration-300 hover:shadow-xl bg-white border border-gray-200"
        >
            <div className="relative flex-shrink-0">
                <img
                    src={page.thumbnail}
                    alt={page.title}
                    className="w-full h-52 object-cover"
                    loading="lazy"
                />
                <div className="absolute top-4 left-4 px-3 py-1 bg-logoColor text-white  font-semibold ">
                    {page.category}
                </div>
            </div>
            <div className="flex flex-col flex-grow p-5 space-y-4">
                <div className="flex flex-col gap-4 mb-auto">
                    <h2 className="text-xl font-bold leading-tight text-gray-900 line-clamp-2">
                        {page.title}
                    </h2>
                    <p className="text-sm text-gray-600 line-clamp-4">
                        {page.subtitle}
                    </p>
                </div>

                <div className="mt-auto pt-4 border-t border-gray-200">
                    <div className="flex justify-between items-center text-sm text-gray-500">
                        <span className="font-medium">{page.createdBy}</span>
                        <div className="flex items-center space-x-2">
                            <span>
                                {new Date(page.createdAt).toLocaleDateString()}
                            </span>
                            <span>•</span>
                            <span>{page.read} min read</span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default PageCard;
